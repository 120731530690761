@import '../../global.scss';

.contact-container {
  background: linear-gradient(45deg, rgb(106, 120, 209), rgb(0, 164, 189));
  display: flex;

  .message-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      gap: 1rem;
      min-width: 40%;

      @include mobile-landsacpe {
        gap: 0.5rem;
        width: 80%;
      }

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include mobile-landsacpe {
          flex-direction: row;
        }

        label {
          display: flex;

          @include mobile-landsacpe {
            flex: 1;
          }

          p {
            color: $bodyColor;
            overflow: hidden;
            display: flex;
            align-items: center;
            gap: 0.3rem;

            @include mobile-landsacpe {
              font-size: 0.6rem;
            }

            .contact-icon {
              color: yellow;
            }
          }
        }

        .text-input {
          outline: 0;
          font-size: 1rem;
          line-height: 1.8rem;
          padding: 0.5rem 1rem;
          width: 100%;
          min-height: 2.2rem;
          border: unset;
          border-radius: 4px;
          outline-color: rgb(84 105 212 / 0.5);
          background-color: rgb(255, 255, 255);
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px;

          @include mobile-landsacpe {
            flex: 3;
            font-size: 0.5rem;
            line-height: 0.9rem;
            padding: 0.2rem 0.5rem;
            width: 100%;
            min-height: 1.1rem;
          }
        }
      }

      .submit {
        border-radius: 12px;
        border: 0;
        box-sizing: border-box;
        color: #08d;
        cursor: pointer;
        font-size: 1.1rem;
        height: 3rem;
        margin-top: 1rem;
        text-align: center;
        width: 100%;

        &:hover {
          background-color: #22223b;
          color: white;
        }

        @include mobile-landsacpe {
          font-size: 0.5rem;
          height: 1.2rem;
          margin-top: 0;
          width: 74%;
          align-self: flex-end;
        }
      }
    }
  }
}
