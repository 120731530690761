@import '../../global.scss';

.topbar {
  width: 100%;
  height: 8vh;
  height: 8svh;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  overflow: hidden;
  background-color: $bodyColor;

  @include mobile-landsacpe {
    height: 16vh;
    height: 16svh;
  }

  .wrapper {
    padding: 0.625rem 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 5vmin;
        font-weight: 700;
        text-decoration: none;
        color: $mainColor;
        margin-right: 2.5rem;
      }
    }

    .right {
      .hamburger {
        width: 2rem;
        height: 1.563rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 0.188rem;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: $bodyColor;

    .wrapper {
      .left {
        .logo {
          color: $bodyColor;
        }
      }
    }

    .right {
      .hamburger {
        span {
          &:first-child {
            background-color: $bodyColor;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: $bodyColor;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
