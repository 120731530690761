$mainColor: #22223b;
$fontColor: #4a4e69;
$bodyColor: white;

$desktopwidth: 75em;
$tabletwidth: 53em;
$mlheight: 32.5em;

@mixin desktop {
  @media (min-width: #{$desktopwidth}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tabletwidth}) {
    @content;
  }
}

@mixin mobile-landsacpe {
  @media (max-height: #{$mlheight}) {
    @content;
  }
}
