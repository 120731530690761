.modal-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .copy-button {
      flex: 1.2;
      display: flex;
      justify-content: center;
    }

    .close-button {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
