@import '../../global.scss';

.slider {
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  transition: all 1s ease;

  .container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .project {
      width: 80%;
      height: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mobile-landsacpe {
        flex-direction: row;
        gap: 2rem;
        margin-bottom: 2rem;
      }

      @include tablet {
        gap: 3rem;
        flex-direction: row;
      }

      .imgContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        @include tablet {
          flex: 1;
        }

        @include desktop {
          flex: 1.2;
        }

        img {
          width: 100%;
          height: 80%;

          &.clickable {
            cursor: pointer;
          }

          @include tablet {
            width: 100%;
            height: 40vh;
            height: 40svh;
          }
        }
      }

      .textContainer {
        flex: 2;

        @include tablet {
          flex: 1;
          width: 70vw;
        }

        @include mobile-landsacpe {
          max-height: 100%;
        }

        @include desktop {
          flex: 2;
        }

        h2 {
          font: bold 500 0.9rem 'Open Sans', sans-serif;
          color: $mainColor;
          overflow: hidden;
          padding-bottom: 0.5rem;

          @include tablet {
            font-size: 1.5rem;
          }

          @include mobile-landsacpe {
            font-size: 0.9rem;
          }

          @include desktop {
            font-size: 2rem;
          }
        }

        p {
          font: normal 500 0.9rem 'Open Sans', sans-serif;
          color: $mainColor;

          @include tablet {
            font-size: 1.5rem;
          }

          @include mobile-landsacpe {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
