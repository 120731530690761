@import '../../global.scss';

.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 7vmin;
    width: 7vmin;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    visibility: hidden;

    @include desktop {
      visibility: visible;
    }

    &.left {
      left: 5vmin;
      cursor: pointer;
    }

    &.right {
      right: 5vmin;
      cursor: pointer;
    }
  }
}
