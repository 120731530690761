@import '../../global.scss';

main {
  background-color: $bodyColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 0;

  @include mobile-landsacpe {
    flex-direction: row;
  }

  .top {
    width: 60vmin;
    display: flex;
    justify-content: center;

    @include tablet {
      width: 40vmin;
    }

    @include desktop {
      width: 30vmin;
    }

    .imgContainer {
      width: 100%;
      background-color: $mainColor;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        height: 85%;
      }
    }
  }

  .bottom {
    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 2rem;

      @include mobile-landsacpe {
        gap: 1rem;
      }

      h1 {
        font-size: 2.1rem;
        color: $mainColor;

        @include mobile-landsacpe {
          font-size: 1.8rem;
        }

        @include tablet {
          font-size: 3rem;
        }

        @include desktop {
          font-size: 4.1rem;
        }
      }

      h2 {
        font-size: 1.5rem;
        color: $mainColor;

        @include mobile-landsacpe {
          font-size: 1rem;
        }

        @include tablet {
          font-size: 2rem;
        }

        @include desktop {
          font-size: 2.5rem;
        }
      }

      h3 {
        font-size: 1rem;
        padding-left: 1rem;
        align-self: flex-start;
        color: $mainColor;

        @include mobile-landsacpe {
          font-size: 0.8rem;
        }

        @include tablet {
          font-size: 1.4rem;
        }

        @include desktop {
          font-size: 2rem;
        }

        span {
          color: #ba181b;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }
    }
  }
}
