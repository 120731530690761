@import '../../global.scss';

.hero {
  height: 100vh;
  height: 100svh;
  background: url('../../../public/media/about.jpg') 0 0 / cover no-repeat;
  -webkit-animation: gradientAnimation 10s ease infinite;
  animation: gradientAnimation 10s ease infinite;
}
@-webkit-keyframes gradientAnimation {
  0% {
    background-position: 0% 30%, 0 0;
  }
  50% {
    background-position: 100% 70%, 0 0;
  }
  100% {
    background-position: 0% 30%, 0 0;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 30%, 0 0;
  }
  50% {
    background-position: 100% 70%, 0 0;
  }
  100% {
    background-position: 0% 30%, 0 0;
  }
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    max-width: 35ch;
    margin: 1rem 1rem;
    color: $bodyColor;
    text-align: center;
    font: normal 800 1.5rem 'Open Sans', sans-serif;

    @include tablet {
      font-size: 2rem;
    }

    @include desktop {
      font-size: 3rem;
    }

    @include mobile-landsacpe {
      font-size: 1rem;
    }
  }

  p {
    max-width: 60ch;
    margin: 1rem 1rem;
    color: $bodyColor;
    font: normal 600 1rem 'Open Sans', sans-serif;

    @include tablet {
      font-size: 1.5rem;
    }

    @include desktop {
      font-size: 2rem;
    }

    @include mobile-landsacpe {
      font-size: 0.9rem;
    }
  }
}
