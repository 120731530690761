@import './global.scss';

.app {
  height: 100vh;
  height: 100svh;

  .sections {
    width: 100%;
    height: 84.9vh;
    height: 84.9svh;
    background-color: lightblue;
    position: relative;
    top: 7.9vh;
    top: 7.9svh;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include mobile-landsacpe {
      height: 75vh;
      height: 75svh;
      top: 16vh;
      top: 16svh;
    }

    > * {
      width: 100vw;
      height: 84.9vh;
      height: 84.9svh;
      scroll-snap-align: start;

      @include mobile-landsacpe {
        height: 75vh;
        height: 75svh;
      }
    }
  }
}
