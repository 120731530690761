.bullet-container {
  position: absolute;
  bottom: 5vmin;
  display: flex;
  gap: 5vmin;

  .bullet {
    background-color: white;
    height: 7vmin;
    width: 7vmin;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: all 1s ease;

    &.active {
      background-color: #4a4e69;
    }
  }
}
