@import '../../global.scss';

footer {
  width: 100%;
  height: 7.3vh;
  height: 7.3svh;
  bottom: 0;
  position: fixed;
  background-color: $bodyColor;

  @include mobile-landsacpe {
    height: 2rem;
  }

  .footerIconsContainer {
    height: 100%;
    padding: 0 1rem;
    display: flex;

    .iconContainer {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.1rem;
      cursor: pointer;

      @include desktop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        overflow: hidden;
      }

      @include mobile-landsacpe {
        align-items: center;
      }

      span {
        display: none;
        padding-left: 0.2rem;
        font-size: 0.5rem;
        word-wrap: break-word;

        @include desktop {
          font-size: 1.1rem;
          display: contents;
        }

        @include mobile-landsacpe {
          font-size: 0.5rem;
        }
      }
    }
  }
}
